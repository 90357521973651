.text-dark-light {
  position: relative;
  font-family: dm sans;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 21px;
  -webkit-transition: all .5s ease-in-out;
  -khtml-transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -ms-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
}

.text-light-dark {
  color: #aaa;
}

.navbar .navbar-brand img {
  width: 140px;
}


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* carousel wrapper */

.carousel-wrapper .carousel {
  padding: 5rem;
  background-color: black;
  color: #fff;
  height: 100vh;
  width: 100vh;
}

.service-wrapper .carousel .box {
  text-align: center;
  background-color: lightgreen;
  height: 300px;
}



.service-wrapper .card {
  border-radius: 20px !important;
  overflow: hidden;
  height: 400px;
}

.service-wrapper .card img {
  height: 400px;
  object-fit: cover;
  width: 100%
}

.service-wrapper .card:after {
  content: "";
  position: absolute;
  z-index: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, transparent 0, transparent 30%, #000);
  opacity: 0.8;
}

.service-wrapper .card .card-content {
  border-radius: 6px;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  padding: 10px;
  height: 85px;
  background: linear-gradient(to top, #000000 30%, #75ff7500 100%);
  padding-top: 34px;
  padding-bottom: 85px;
  z-index: 1;
}

.service-wrapper .card .card-content h5 {
  font-size: 1.5rem;
  color: #fff;
}

.service-wrapper .card .card-content span {
  color: #ffffff;
  background: #000;
  padding: 3px 10px;
  border-radius: 30px;
}

.service-wrapper .cursive {
  font-size: 24px;
  font-family: 'Dancing Script', sans-serif;
}


.service-wrapper h1 {
  position: relative;
  z-index: 1;
  color: #000;
  font-family: "DM Sans", sans-serif;
  font-size: 3.75rem;
  font-weight: 600;
  line-height: 4.625rem;
  letter-spacing: 0px;
  margin-top: 10px;
  margin-bottom: 15px;
  transition: all 0.5s ease-in-out;

}

.service-wrapper h1 span {
  color: #ccc;
}

/* carousel wrapper end*/


/* bottom wrapper */

.bottom-wrapper {

  background-size: cover !important;
  background-position: center !important;

}

.bottom-wrapper .bottom-box {
  padding: 105px 0 135px;
  text-align: center;
}

.bottom-wrapper .bottom-box h5.sub-title {
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
  text-transform: none;
  letter-spacing: 1px;
  margin-bottom: 5px;
}


.bottom-wrapper .bottom-box h2 {
  color: #1b1b1b;
  font-size: 5rem;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 1.5em;
  margin-bottom: 10px;
}


.bottom-wrapper .bottom-box h2 a {
  color: #ffffff !important;
  font-size: 3rem;
  font-weight: 700;
  text-transform: none;
  text-decoration: none;
}


.bottom-wrapper .bottom-box h2.the-title span {
  color: #da0d2b;
  border-bottom: 3px solid #da0d2b;
}

/* bottom wrapper end */


/* partner wrapper */

.partner-wrapper {}

.partner-wrapper h1 {
  font-size: 3rem;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 1.5em;
  margin-bottom: 10px;
}




.partner-wrapper .content {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: auto;
  background: #fff;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 20px;
}

.partner-wrapper .content .content-overlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.partner-wrapper .content:hover .content-overlay {
  opacity: 1;
}

img.content-image {
  height: 140px;
  width: 100%;
  object-fit: contain;
  border-radius: 20px;
}

.partner-wrapper .content-details {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.partner-wrapper .content:hover .content-details {
  top: 50%;
  left: 50%;
  opacity: 1;
}

.partner-wrapper .content-details h3 {
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.15em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
}

.partner-wrapper .content-details p {
  color: #fff;
  font-size: 0.8em;
}

.fadeIn-bottom {
  top: 80%;
}

.fadeIn-top {
  top: 20%;
}

.fadeIn-left {
  left: 20%;
}

.fadeIn-right {
  left: 80%;
}


/*  */

.service-wrapper .slick-slide {
  padding: 5px;
}




/* top wrapper */
.top-wrapper {
  height: 600px;
  background-size: cover;
  background-position: right -326px;
  background-repeat: no-repeat;
}

.top-wrapper h3 {
  font-size: 74px;
  font-weight: 800;
}


.top-wrapper h3 span {
  font-size: 40px;
  font-weight: 500;
}


.gallery-wrapper img {
  height: 350px;
  object-fit: cover;
}

.gallery-wrapper p {

  font-size: 24px;
}

.gallery-wrapper h1 {
  position: relative;
  z-index: 1;
  color: #000;
  font-family: "DM Sans", sans-serif;
  font-size: 45px;
  font-weight: 600;
  letter-spacing: 0px;
  margin-top: 10px;
  margin-bottom: 15px;
  transition: all 0.5s ease-in-out;

}

.gallery-wrapper h1 span {
  font-family: inherit;
  font-style: normal;
  /* background: linear-gradient(90deg, #57e2d0 0%, #ffb84d 50%, #ff0080 100%); */
  animation: gradient-animation 6s infinite ease-in-out;
  background-size: 300% 300%;
  background-clip: text;
  -webkit-background-clip: text;
  letter-spacing: -1.92px;
  font-weight: 700;
  transition: inherit;
  color: #ff3131;
}

/* gallery wrapper end */


/* gallery slider */

.slider-wrapper {
  background-color: #fff !important;
}

.slider-wrapper .carousel-item {
  height: 500px;
}

.slider-wrapper .slider-img {
  margin-bottom: 15px;
  height: 476px;
  background-size: cover !important;
  background-position: top;
  overflow: hidden !important;


}

.slider-wrapper .slider-img2 {
  margin-bottom: 15px;
  height: 230px;
  background-size: cover !important;
  background-position: top;
  overflow: hidden !important;
  visibility: visible;


}

.slider-wrapper .slider-img3 {
  margin-bottom: 15px;
  height: 230px;
  background-size: cover !important;
  overflow: hidden !important;
  background-color: #eee;
  text-align: center;
  align-items: center;
  visibility: visible;
  padding: 15px;
}

.slider-wrapper .slider-img3 h5 {
  /* font-family: "Pacifico", cursive; */
  font-weight: 700;
  font-size: 30px;
  color: #6f6f6f;
}

.slider-wrapper .slider-img3 p {
  font-weight: 500;
  font-size: 13px;
  color: #6f6f6f;
}

.slider-wrapper .slider-img3 a {
  color: #000;
  border: 1px solid #000;
  background: transparent;
  padding: 3px 10px;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
}

.slider-wrapper .slider-img3 a:hover {
  color: #fff;
  border: 1px solid #000;
  background: #000;
  padding: 3px 10px;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
}

.slider-wrapper .slider-img4 {
  margin-bottom: 15px;
  height: 228px;
  background-size: cover !important;
  background-position: center;
  overflow: hidden !important;


}

.animate {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}


.one {
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.two {
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.three {
  -webkit-animation-delay: 1.0s;
  -moz-animation-delay: 1.0s;
  animation-delay: 1.0s;
}

.four {
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 305s;
  animation-delay: 0.5s;
}

.five {
  -webkit-animation-delay: 4.5s;
  -moz-animation-delay: 4.5s;
  animation-delay: 4.5s;
}

.six {
  -webkit-animation-delay: 5.5s;
  -moz-animation-delay: 5.5s;
  animation-delay: 5.5s;
}

.seven {
  -webkit-animation-delay: 6.5s;
  -moz-animation-delay: 6.5s;
  animation-delay: 6.5s;
}

.eight {
  -webkit-animation-delay: 7.5s;
  -moz-animation-delay: 7.5s;
  animation-delay: 7.5s;
}

/*=== Animations start here  ===*/


/*==== FADE IN RIGHT ===*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

/*=== FADE IN  ===*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}


/*=== FADE IN RIGHT Big ===*/
.fadeInRightBig {
  -webkit-animation-name: fadeInRightBig;
  animation-name: fadeInRightBig;
}

@-webkit-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}



/* gallery slider end */


/* about us hude */
.about-us-hude {
  background-color: #ffffff;
  height: 250px;
  color: #222328;
  margin-bottom: 50px;
  background-image: url(https://livewp.site/wp/md/moone/wp-content/uploads/sites/5/2018/03/41.jpg);
  padding-left: 37px;
  padding-right: 37px;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
}

.about-us-hude .page-header-content {
  position: relative;
  z-index: 2;
}

.about-us-hude .heading {
  position: relative;
  font-size: 48px;
  font-weight: 600;
  line-height: 60px;
  margin-top: 0px;
  margin-bottom: 40px;
  padding: 0px 20px 35px 20px;
  color: #ffffff;
  display: inline-block;
}


.about-us-hude .icon {
  position: absolute;
  font-size: 16px;
  color: #ffffff;
  width: 100%;
  left: 50%;
  top: 70%;
  transform: translateX(-50%);
}




.about-us-hude .icon:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 40%;
  height: 2px;
  background: transparent;
  border-bottom: 1px solid #999;
}

.about-us-hude .icon:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 40%;
  height: 2px;
  background: transparent;
  border-bottom: 1px solid #999;
}

.about-us-hude p {
  font-family: 'Playfair Display', serif;
  font-size: 13px;
  font-style: italic;
  color: #ffffff;
  letter-spacing: 0.5px;
  line-height: 26px;
}


.about-info-wrapper ul {
  margin-top: 30px;
}


.about-info-wrapper h4 {
  font-size: 17px;
}

.about-info-wrapper p {
  font-size: 13px;
}

.paragraph-quote {
  color: white
}

/* about us hude end*/


/* services */


.service-wrapper-new ul {
  padding-left: 0px;
}

.service-wrapper-new ul li {
  color: #fff;
  list-style: none;
  padding-left: 0px;
  margin-bottom: 20px;
  font-size: 14px;
}

.service-wrapper-new ul li::before {
  color: #fff;
  content: '✓';
  padding-right: 10px;

}

.service-wrapper-new .card:nth-child(1) {
  background: linear-gradient(to right, rgb(248, 87, 166), rgb(255, 88, 88));
}


.service-wrapper-new .card img {
  position: absolute;
  opacity: 0.4;
  z-index: 0;
  object-fit: contain;
  bottom: 0;
  width: 55%;
  right: 0;
}

.service-wrapper-new .bg-dark-img img {
  height: 497px;
  border-radius: 10px;
  object-fit: cover;
}

.service-button button,
a {
  margin-right: 1rem;
}

/* services end */


/* our work process */

.our-process {
  display: flex;
  gap: 0px;
  position: relative;
  margin-bottom: 20px;
  cursor: pointer;
}


/* our work process */




.swiper-slide {
  position: relative;

}

.swiper-slide img {
  width: 300px !important;
  height: 400px !important;
  object-fit: cover;

}

.swiper-slide .slider-content {
  background: linear-gradient(180deg, transparent -4.82%, #000000 87.02%);
  border-radius: 6px;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 300px;
  height: auto;
  padding: 10px;
  padding-top: 20px;
  text-align: center;
}

.swiper-slide .slider-content h4 {
  font-weight: 600;
  font-size: 22px;
}

.swiper-slide .slider-content p {
  font-size: 16px;
}


.swiper-slide .slider-content p span {
  font-size: 26px;
  font-weight: 600;
}

.swiper-slide .slider-content a {
  background: transparent;
  color: #fff !important;
  padding: 10px;
  border: 2px solid #fff;
  margin-bottom: 20px;
}


/* service details */

.service-details h4 {
  font-size: 60px;
  background: linear-gradient(90deg, #43FFEC 0%, #FFB84D 50%, #FF0080 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: var(--transition-base);
}


.service-details .float-right {
  float: right;
}

.service-details .float-right span {
  color: #fff;
  font-size: 14px;
}


.service-details .float-right p {
  color: #fff;
  font-size: 30px;
  font-weight: 600;
}


/* video section */

/* Hero Video */
.outter.hero-video {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 767px) {
  .outter.hero-video {
    height: 325px;
  }
}

.hero-video .video-container {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}



@media (max-width: 767px) {
  .hero-video .video-container {
    height: 325px;
  }
}

.hero-video video {
  object-fit: contain;
  width: 100%;
  /* height: 700px; */
  /* border-radius: 30px; */
}

@media (max-width: 767px) {
  .hero-video video {
    height: 325px;
  }
}

.hero-video .video-container:after {
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.hero-video h1 {
  text-transform: uppercase;
  margin: 0 0 1rem;
  padding: 0;
  line-height: 1;
  color: white;
}

@media (max-width: 767px) {
  .hero-video h1 {
    font-size: 32px;
  }
}

@media (min-width: 768px) {
  .hero-video h1 {
    font-size: 52px;
  }
}

.hero-video .desc {
  color: white;
  font-weight: 400;
  font-size: 18px;
}

.hero-video .callout {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  text-align: center;
  position: relative;
  z-index: 10;
  width: 70%;
  margin: auto;
}

@media (max-width: 767px) {
  .hero-video .callout {
    width: 90%;
  }
}

.hero-video .button {
  text-transform: uppercase;
  background-color: transparent;
  border-radius: 0px;
  margin-top: 20px;
  background-color: #ff1919;
  padding: 15px 30px;
  border-radius: 0px;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

@media (max-width: 767px) {
  .hero-video .button {
    padding: 10px 20px;
  }
}

.hero-video .button:hover {
  cursor: pointer;
  background-color: rgb(141, 33, 0);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
  background-color: #1f2e43 !important;
  background: radial-gradient(circle at 55% 92%, #426691 0 12%, transparent 12.2%), radial-gradient(circle at 94% 72%, #426691 0 10%, transparent 10.2%), radial-gradient(circle at 20% max(78%, 350px), #263a53 0 7%, transparent 7.2%), radial-gradient(circle at 0% 0%, #263a53 0 40%, transparent 40.2%), #1f2e43;
  border-radius: 10px 0 0 10px;
}

.modal-body button {
  background-color: white;
  color: rgb(52, 52, 132)
}

.modal-btn {
  display: flex;
  justify-content: center;
}

.modal-btn button {
  background-color: #75ff75;
  font-weight: bold;
}

/*Footer */
.last-div-footer {
  text-align: center;
  /* background-color: #000000; */
  padding: 1rem 0;
}



.footer {
  padding: 100px 0px 0px;
  background-color: #030a16;
}


.footer .widget1 p {
  font-size: 16px;
  color: rgba(255, 255, 255, .6);
  margin-top: 26px;
}

.footer .socialLinks {
  margin-top: 28px;
}

.socialLinks ul li {
  display: inline-block;
}


.socialLinks ul li a i {
  display: block;
  margin: 0 2px;
  width: 40px;
  height: 40px;
  background: #fafafa;
  border-radius: 50%;
  text-align: center;
  margin-right: 5px;
  line-height: 40px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.socialLinks ul li a .fa-facebook-f {
  color: #4267b2;
}

.socialLinks ul li a .fa-twitter {
  color: #1da1f2;
}

.socialLinks ul li a .fa-google-plus-g {
  color: #dd5044;
}

.socialLinks ul li a .fa-linkedin-in {
  color: #0177b5;
}


.socialLinks ul li a:hover i {
  color: #fff;
}

.socialLinks ul li a:hover .fa-facebook-f {
  background: #4267b2;
}

.socialLinks ul li a:hover .fa-twitter {
  background: #1da1f2;
}

.socialLinks ul li a:hover .fa-google-plus-g {
  background: #dd5044;
}

.socialLinks ul li a:hover .fa-linkedin-in {
  background: #0177b5;
}

footer .logo {
  max-width: 200px;
}

.footer h5 {
  font-weight: 500;
  margin-bottom: 28px;
  text-transform: capitalize;
  font-weight: 600;
  color: #fff;
  font-size: 21px
}

.footer .widget2 .media img {
  margin-right: 20px;
  max-width: 100px;
}

.footer .widget2 .media p {
  font-size: 16px;
  color: rgba(255, 255, 255, .8);
  ;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  font-weight: 600;
  line-height: 26px;
}

.footer .widget2 .media span {
  font-size: 12px;
  color: #ffb606;
  text-transform: uppercase;
  margin-top: 15px;
  display: block;
}

.footer .widget2 .media {
  margin-bottom: 20px;
}

.footer .widget3 ul li a,
.footer .widget4 ul li a {
  font-size: 16px;
  color: rgba(255, 255, 255, .6);
  ;
  text-transform: capitalize;
  margin-bottom: 13px;
  display: block;
}

.footer .widget3 ul li a:hover,
.footer .widget4 ul li a:hover,
.footer .widget2 a:hover p {
  color: #ffb606;
}

.footer .widget3 h5 {
  margin-bottom: 22px;
  font-weight: 600;
  color: #fff;
  font-size: 21px;
  line-height: 32px;
}

.footer .widget4 h5 {
  margin-bottom: 22px;
  font-weight: 600;
  color: #fff;
  font-size: 21px;
}

.copyRightArea {
  margin-top: 83px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding: 25px 0px 28px;
}

.copyRightArea p {
  color: rgba(255, 255, 255, 0.6);
}


.item-icon {
  position: relative;
  line-height: 1;
  margin-bottom: 11px;
  -webkit-transition: all .5s ease-in-out;
  -khtml-transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -ms-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  z-index: 1;
  font-size: 42px;
  margin-bottom: 20px;
  color: var(--primary-color);
  max-height: 42px;
  display: flex;
  align-items: center;
}

.item-icon svg {
  max-height: 42px;
  max-width: 52px;
}

.item-title {
  position: relative;
  font-family: dm sans;
  font-weight: 700;
  font-size: 20px;
  -webkit-transition: all .5s ease-in-out;
  -khtml-transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -ms-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
}

.item-title:after {
  content: '';
  width: 67px;
  height: 1px;
  display: inline-block;
  background-color: rgba(0, 0, 0, .1);
  -webkit-transition: all .5s ease-in-out;
  -khtml-transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -ms-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
}


.our-process-box {
  position: relative;
  padding: 25px 31px;
  -webkit-transition: all .5s ease-in-out;
  -khtml-transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -ms-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
}

.our-process .explore-p {
  width: 60%;
}

.gradient-text {
  font-family: "DM Sans", Sans-serif;
  font-weight: 700;
  margin: 0px 0px 10px 0px;
  font-size: 45px;
}

.gradient-text span {
  font-family: inherit;
  font-style: normal;
  animation: gradient-animation 6s infinite ease-in-out;
  background-size: 300% 300%;
  background-clip: text;
  -webkit-background-clip: text;
  font-weight: 700;
  transition: inherit;
  color: #ff3131;
}


@keyframes gradient-animation {
  0% {
    background-position: 0;
  }

  50% {
    background-position: 100%;
  }

  100% {
    background-position: 0;
  }
}


.grd-img {
  position: absolute;
  width: 72%;
  top: 0;
}

.page-wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 11111;
  top: 35px;
}

footer p,
footer strong,
footer b,
footer {
  color: #b0b0b0;
}

.footer-top {
  background: #303030;
  background-size: cover;
  background-position: center;
  padding: 0 0 20px;
  font-family: rubik;
  padding-top: 30px;

}

.footer-top,
.footer-bottom {
  background-color: #1c1f2f;
}

.footer-bottom {
  padding: 15px 0;
  border-top: 1px solid #313646;
  background-color: #181828 !important;
  color: #b0b0b0;
  font-family: rubik;

  color: #99a9b5;
  padding-top: 15px;
}

.footer-site-info {
  font-size: 92.86%;
}

#footer-navigation,
#footer-navigation li a:hover,
.custom-footer,
.custom-footer li a:hover {
  color: white;
}

#footer-navigation,
#footer-navigation li a,
.custom-footer,
.custom-footer li a {
  color: #99a9b5;
  padding-top: 15px;
}

.footer-bottom ul {
  margin: 0;
}

.inline-inside {
  font-size: 0;
  line-height: 0;
}

.clearfix:after,
.clearfix:before {
  content: "";
  display: table;
}

#footer-menu li {
  display: inline-block;
  padding: 0 21px;
  position: relative;
  line-height: 1;
}

#footer-navigation,
#footer-navigation li a,
.custom-footer,
.custom-footer li a {
  color: #99a9b5;
  padding-top: 15px;
}

#footer-navigation,
#footer-navigation li a,
.custom-footer,
.custom-footer li a {
  color: #99a9b5;
  padding-top: 15px;
}

#footer-menu li+li:before {
  content: '';
  width: 0;
  height: 100%;
  position: absolute;
  left: -1px;
  top: 0;
  font-size: 0;
  border-left: 1px solid #232234;
  border-right: 1px solid #333146;
}


navigation li a,
.custom-footer,
.custom-footer li a {
  color: #99a9b5;
  padding-top: 15px;
}

#footer-socials {
  text-align: right;
}

#footer-socials .socials {
  text-align: right;
  margin: 0 -7px;
  display: inline-block;
  vertical-align: middle;
}

a.socials-item {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  margin: 0 5px;
  line-height: 16px;
  padding: 10px;
  border-radius: 50%;
  background-color: #141421;
  border: 1px solid #2e2e4c;
  box-shadow: 3px 9px 16px rgb(0, 0, 0, 0.4), -3px -3px 10px rgba(255, 255, 255, 0.06), inset 14px 14px 26px rgb(0, 0, 0, 0.3), inset -3px -3px 15px rgba(255, 255, 255, 0.05);
}

.socials-item i {
  display: inline-block;
  font-weight: normal;
  width: 1em;
  height: 1em;
  line-height: 1;
  font-size: 16px;
  text-align: center;
  vertical-align: top;
  font-feature-settings: normal;
  font-kerning: auto;
  font-language-override: normal;
  font-size-adjust: none;
  font-stretch: normal;
  font-style: normal;
  font-synthesis: weight style;
  font-variant: normal;
  font-weight: normal;
  text-rendering: auto;
}

.facebook {
  color: #4e64b5;
}

.twitter {
  color: #00aced;
}

.instagram {
  color: #9a8f62;
}

.youtube {
  color: #c82929;
}

.telegram {
  color: #2ca5e0;
}


a.socials-item:hover {
  box-shadow: 0 0px 20px rgba(84, 1, 74, 0.7);
  border-color: rgba(255, 6, 224, 0.61);
  background: linear-gradient(to right, rgba(255, 9, 9, 0.12941176470588237), #c000ffb5, rgba(255, 0, 94, 0.14));
}

.footer-bottom a:hover {
  color: white;
}


footer p,
footer li {
  font-size: 15px;
  line-height: 22px;
}


.widget {
  margin-bottom: 50px;
}

.footer-title {
  margin-bottom: 40px;
  color: #fff;
  font-weight: 500;
  text-transform: capitalize;
  padding-bottom: 15px;
  font-size: 16px;
  position: relative;
}

.footer-title:after {
  width: 50px;
  background: #fff;
  opacity: 0.2;
  height: 1px;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
}

.gem-contacts-item {
  padding-top: 10px;
  font-size: 15px;
}

.gem-contacts-item i {
  padding-right: 10px;
}

footer .widget ul {
  list-style: none;
  margin-top: 5px;
}

.posts li {
  border-bottom: 1px solid #393d50;
  padding-bottom: 12px;
  padding-top: 6px;
}

footer p,
footer li {
  font-size: 15px;
  line-height: 22px;
}

.gem-pp-posts-date {
  color: #00bcd4;
  font-size: 89.5%;
}

footer p {
  line-height: 24px;
  margin-bottom: 10px;
  font-size: 15px;
}

.wpcf7-form-control-wrap .wpcf7-form-control {
  padding: 7px !important;
  width: 100%;
}

.wpcf7-form-control-wrap input {
  background: #1c1f2f;
  overflow: hidden;
  border: 1px solid #2e344d;
  background-color: #1c1f2f;
  box-shadow: 10px 10px 36px rgb(0, 0, 0, 0.5), -13px -13px 23px rgba(255, 255, 255, 0.03);
  border-radius: 5px;
  transition: all 0.3s ease-in-out 0s;
}

.wpcf7-form-control-wrap input:hover {
  background-color: transparent;
  box-shadow: 10px 10px 36px rgb(0, 0, 0, 0.5), -13px -13px 23px rgba(255, 255, 255, 0.03), inset 14px 14px 70px rgb(0, 0, 0, 0.2), inset -15px -15px 30px rgba(255, 255, 255, 0.04);
  border-color: rgba(255, 255, 255, 0.2);
  color: white;
}

.wpcf7 .wpcf7-form .contact-form-footer textarea {
  height: 160px;
  width: 100%;
}

.wpcf7-form-control-wrap textarea:hover {
  background-color: transparent;
  box-shadow: 10px 10px 36px rgb(0, 0, 0, 0.5), -13px -13px 23px rgba(255, 255, 255, 0.03), inset 14px 14px 70px rgb(0, 0, 0, 0.2), inset -15px -15px 30px rgba(255, 255, 255, 0.04);
  border-color: rgba(255, 255, 255, 0.2);
  color: white;
}

.wpcf7-form-control-wrap textarea {
  background: #1c1f2f;
  overflow: hidden;
  border: 1px solid #2e344d;
  background-color: #1c1f2f;
  box-shadow: 10px 10px 36px rgb(0, 0, 0, 0.5), -13px -13px 23px rgba(255, 255, 255, 0.03);
  border-radius: 5px;
  transition: all 0.3s ease-in-out 0s;
}

textarea {
  overflow: auto;
  resize: vertical;
}

.wpcf7 .wpcf7-form .contact-form-footer .wpcf7-submit {
  width: 100%;
  padding: 11px;
  margin: 0;
  line-height: 0;
}

.wpcf7-form .contact-form-footer .wpcf7-submit {
  background-color: #394050;
  color: #99a9b5;
  border: none;
  cursor: pointer;
  padding: 15px 40px;
  font-size: 14px;
  font-weight: 400;
  transition: all 0.5s;
  letter-spacing: 2px;
  color: rgba(255, 255, 255, .5);
  box-shadow: none;
  text-transform: uppercase;
  outline: none !important;
  background-color: #1c1f2f;
  border-radius: 5px;
  min-width: 140px;
  /* box-shadow: 10px 10px 36px rgb(0,0,0,0.5), -13px -13px 23px rgba(255,255,255, 0.03), inset 14px 14px 70px rgb(0,0,0,0.2), inset -15px -15px 30px rgba(255,255,255, 0.04); */
  box-shadow: 3px 9px 16px rgb(0, 0, 0, 0.4), -3px -3px 10px rgba(255, 255, 255, 0.06), inset 14px 14px 26px rgb(0, 0, 0, 0.3), inset -3px -3px 15px rgba(255, 255, 255, 0.05);
  border-width: 1px 0px 0px 1px;
  border-style: solid;
  border-color: #2e344d;
  transition: all 0.3s ease-in-out 0s;
}

.wpcf7-form input[type=submit] {
  height: 40px;
  line-height: 21px;
  padding: 10px 40px;
  font-size: 14px;
}

.posts li a {
  color: #99a9b5;
}

.wpcf7-form .contact-form-footer .wpcf7-submit:hover {
  box-shadow: 0 0px 20px rgba(84, 1, 74, 0.7);
  border-color: rgba(255, 6, 224, 0.61);
  background: linear-gradient(to right, rgba(255, 9, 9, 0.12941176470588237), #c000ffb5, rgba(255, 0, 94, 0.14));
  color: white;
}

img {
  border-style: none;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

.widget_gallery a {
  display: inline-block;
}

footer .widget ul {
  list-style: none;
  margin-top: 5px;
}

.widget_gallery ul {
  padding-left: 0;
  display: table;
}

.widget_gallery li {
  display: inline-block;
  width: 33.33%;
  float: left;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  padding: 2px;
}

.widget_gallery.gallery-grid-4 li {
  width: 30%;
}


#waterdrop {
  height: 30px;
}

#waterdrop canvas {
  bottom: -70px !important;
}

.footer-site-info {
  padding-top: 10px;
}

.footer-wrapper {
  position: relative;
  bottom: 0;
  width: 100%;
  z-index: 11111;
  top: 35px;
}

footer p,
footer strong,
footer b,
footer {
  color: #b0b0b0;
}

.footer-top {
  background: #303030;
  background-size: cover;
  background-position: center;
  padding: 0 0 20px;
  font-family: rubik;
  padding-top: 30px;

}

.footer-top,
.footer-bottom {
  background-color: #1c1f2f;
}

.footer-bottom {
  padding: 15px 0;
  border-top: 1px solid #313646;
  background-color: #181828 !important;
  color: #b0b0b0;
  font-family: rubik;

  color: #99a9b5;
  padding-top: 15px;
}

.footer-site-info {
  font-size: 92.86%;
}

#footer-navigation,
#footer-navigation li a:hover,
.custom-footer,
.custom-footer li a:hover {
  color: white;
}

#footer-navigation,
#footer-navigation li a,
.custom-footer,
.custom-footer li a {
  color: #99a9b5;
  padding-top: 15px;
}

.footer-bottom ul {
  margin: 0;
}

.inline-inside {
  font-size: 0;
  line-height: 0;
}

.clearfix:after,
.clearfix:before {
  content: "";
  display: table;
}

#footer-menu li {
  display: inline-block;
  padding: 0 21px;
  position: relative;
  line-height: 1;
}

#footer-navigation,
#footer-navigation li a,
.custom-footer,
.custom-footer li a {
  color: #99a9b5;
  padding-top: 15px;
}

#footer-navigation,
#footer-navigation li a,
.custom-footer,
.custom-footer li a {
  color: #99a9b5;
  padding-top: 15px;
}

#footer-menu li+li:before {
  content: '';
  width: 0;
  height: 100%;
  position: absolute;
  left: -1px;
  top: 0;
  font-size: 0;
  border-left: 1px solid #232234;
  border-right: 1px solid #333146;
}


navigation li a,
.custom-footer,
.custom-footer li a {
  color: #99a9b5;
  padding-top: 15px;
}

#footer-socials {
  text-align: right;
}

#footer-socials .socials {
  text-align: right;
  margin: 0 -7px;
  display: inline-block;
  vertical-align: middle;
}

a.socials-item {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  margin: 0 5px;
  line-height: 16px;
  padding: 10px;
  border-radius: 50%;
  background-color: #141421;
  border: 1px solid #2e2e4c;
  box-shadow: 3px 9px 16px rgb(0, 0, 0, 0.4), -3px -3px 10px rgba(255, 255, 255, 0.06), inset 14px 14px 26px rgb(0, 0, 0, 0.3), inset -3px -3px 15px rgba(255, 255, 255, 0.05);
}

.socials-item i {
  display: inline-block;
  font-weight: normal;
  width: 1em;
  height: 1em;
  line-height: 1;
  font-size: 16px;
  text-align: center;
  vertical-align: top;
  font-feature-settings: normal;
  font-kerning: auto;
  font-language-override: normal;
  font-size-adjust: none;
  font-stretch: normal;
  font-style: normal;
  font-synthesis: weight style;
  font-variant: normal;
  font-weight: normal;
  text-rendering: auto;
}

.facebook {
  color: #4e64b5;
}

.twitter {
  color: #00aced;
}

.instagram {
  color: #9a8f62;
}

.youtube {
  color: #c82929;
}

.telegram {
  color: #2ca5e0;
}


a.socials-item:hover {
  box-shadow: 0 0px 20px rgba(84, 1, 74, 0.7);
  border-color: rgba(255, 6, 224, 0.61);
  background: linear-gradient(to right, rgba(255, 9, 9, 0.12941176470588237), #c000ffb5, rgba(255, 0, 94, 0.14));
}

.footer-bottom a:hover {
  color: white;
}


footer p,
footer li {
  font-size: 15px;
  line-height: 22px;
}


.widget {
  margin-bottom: 50px;
}

.footer-title {
  margin-bottom: 40px;
  color: #fff;
  font-weight: 500;
  text-transform: capitalize;
  padding-bottom: 15px;
  font-size: 16px;
  position: relative;
}

.footer-title:after {
  width: 50px;
  background: #fff;
  opacity: 0.2;
  height: 1px;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
}

.gem-contacts-item {
  padding-top: 10px;
  font-size: 15px;
}

.gem-contacts-item i {
  padding-right: 10px;
}

footer .widget ul {
  list-style: none;
  margin-top: 5px;
}

.posts li {
  border-bottom: 1px solid #393d50;
  padding-bottom: 12px;
  padding-top: 6px;
}

footer p,
footer li {
  font-size: 15px;
  line-height: 22px;
}

.gem-pp-posts-date {
  color: #00bcd4;
  font-size: 89.5%;
}

footer p {
  line-height: 24px;
  margin-bottom: 10px;
  font-size: 15px;
}

.wpcf7-form-control-wrap .wpcf7-form-control {
  padding: 7px !important;
  width: 100%;
}

.wpcf7-form-control-wrap input {
  background: #1c1f2f;
  overflow: hidden;
  border: 1px solid #2e344d;
  background-color: #1c1f2f;
  box-shadow: 10px 10px 36px rgb(0, 0, 0, 0.5), -13px -13px 23px rgba(255, 255, 255, 0.03);
  border-radius: 5px;
  transition: all 0.3s ease-in-out 0s;
}

.wpcf7-form-control-wrap input:hover {
  background-color: transparent;
  box-shadow: 10px 10px 36px rgb(0, 0, 0, 0.5), -13px -13px 23px rgba(255, 255, 255, 0.03), inset 14px 14px 70px rgb(0, 0, 0, 0.2), inset -15px -15px 30px rgba(255, 255, 255, 0.04);
  border-color: rgba(255, 255, 255, 0.2);
  color: white;
}

.wpcf7 .wpcf7-form .contact-form-footer textarea {
  height: 160px;
  width: 100%;
}

.wpcf7-form-control-wrap textarea:hover {
  background-color: transparent;
  box-shadow: 10px 10px 36px rgb(0, 0, 0, 0.5), -13px -13px 23px rgba(255, 255, 255, 0.03), inset 14px 14px 70px rgb(0, 0, 0, 0.2), inset -15px -15px 30px rgba(255, 255, 255, 0.04);
  border-color: rgba(255, 255, 255, 0.2);
  color: white;
}

.wpcf7-form-control-wrap textarea {
  background: #1c1f2f;
  overflow: hidden;
  border: 1px solid #2e344d;
  background-color: #1c1f2f;
  box-shadow: 10px 10px 36px rgb(0, 0, 0, 0.5), -13px -13px 23px rgba(255, 255, 255, 0.03);
  border-radius: 5px;
  transition: all 0.3s ease-in-out 0s;
}

textarea {
  overflow: auto;
  resize: vertical;
}

.wpcf7 .wpcf7-form .contact-form-footer .wpcf7-submit {
  width: 100%;
  padding: 11px;
  margin: 0;
  line-height: 0;
}

.wpcf7-form .contact-form-footer .wpcf7-submit {
  background-color: #394050;
  color: #99a9b5;
  border: none;
  cursor: pointer;
  padding: 15px 40px;
  font-size: 14px;
  font-weight: 400;
  transition: all 0.5s;
  letter-spacing: 2px;
  color: rgba(255, 255, 255, .5);
  box-shadow: none;
  text-transform: uppercase;
  outline: none !important;
  background-color: #1c1f2f;
  border-radius: 5px;
  min-width: 140px;
  /* box-shadow: 10px 10px 36px rgb(0,0,0,0.5), -13px -13px 23px rgba(255,255,255, 0.03), inset 14px 14px 70px rgb(0,0,0,0.2), inset -15px -15px 30px rgba(255,255,255, 0.04); */
  box-shadow: 3px 9px 16px rgb(0, 0, 0, 0.4), -3px -3px 10px rgba(255, 255, 255, 0.06), inset 14px 14px 26px rgb(0, 0, 0, 0.3), inset -3px -3px 15px rgba(255, 255, 255, 0.05);
  border-width: 1px 0px 0px 1px;
  border-style: solid;
  border-color: #2e344d;
  transition: all 0.3s ease-in-out 0s;
}

.wpcf7-form input[type=submit] {
  height: 40px;
  line-height: 21px;
  padding: 10px 40px;
  font-size: 14px;
}

.posts li a {
  color: #99a9b5;
}

.wpcf7-form .contact-form-footer .wpcf7-submit:hover {
  box-shadow: 0 0px 20px rgba(84, 1, 74, 0.7);
  border-color: rgba(255, 6, 224, 0.61);
  background: linear-gradient(to right, rgba(255, 9, 9, 0.12941176470588237), #c000ffb5, rgba(255, 0, 94, 0.14));
  color: white;
}

img {
  border-style: none;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

.widget_gallery a {
  display: inline-block;
}

footer .widget ul {
  list-style: none;
  margin-top: 5px;
}

.widget_gallery ul {
  padding-left: 0;
  display: table;
}

.widget_gallery li {
  display: inline-block;
  width: 33.33%;
  float: left;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  padding: 2px;
}

.widget_gallery.gallery-grid-4 li {
  width: 30%;
}


#waterdrop {
  height: 30px;
}

#waterdrop canvas {
  bottom: -70px !important;
}

.footer-site-info {
  padding-top: 10px;
}


.progress-container {
  width: 90%;
  background-color: #f3f3f3;
  border-radius: 10px;
  overflow: hidden;
  height: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.progress-bar {
  height: 100%;
  text-align: center;
  line-height: 30px;
  font-weight: bold;
  color: white;
  border-radius: 10px;
  background: linear-gradient(90deg, #ff7e5f, #feb47b);
  transition: width 0.5s ease-in-out;
}



.stats-section {
  max-width: 800px;
  margin: auto;
  text-align: left;
}

h3 {
  font-size: 20px;
  font-weight: bold;
}

.highlight {
  color: #00bcd4;
  /* Light blue */
}

p {
  font-size: 14px;
  color: #666;
  line-height: 1.5;
}

.stats-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 20px;
}

.stat-box {
  text-align: left;
  display: flex;
  gap: 30px;
  border-bottom: 2px solid #ccc;
}

.stat-box h1 {
  font-size: 3.8rem;
}

h2 {
  font-size: 32px;
  font-weight: bold;
  margin: 0;
}

h2 span {
  font-size: 22px;
}

.label {
  font-size: 12px;
  color: #777;
  font-weight: bold;
  margin-top: 5px;
}


.pxl-overlay-gradient-bottom {
  position: absolute;
  bottom: -70px;
  width: 100%;
  z-index: 1;
  height: 212px;
  z-index: 2;
  background: linear-gradient(0deg, #fff 57.03%, rgba(255, 255, 255, .79) 75.21%, rgba(255, 255, 255, 0) 95.2%);
}

.pxl-overlay-gradient-top {
  position: absolute;
  top: -150px;
  width: 100%;
  z-index: 1;
  height: 302px;
  z-index: 2;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #fff 50%, rgba(255, 255, 255, 0) 95.2%);
}


.img-p img {
  border-radius: 20px;
  margin-bottom: 15px;
}




.features-section {
  max-width: 800px;
  margin: auto;
}

.features-container {
  display: flex;
  justify-content: space-between;
  gap: 40px;
}

.features-list {
  list-style: none;
  padding: 0;
}

.features-list li {
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.checkmark {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: #2cd4c1;
  color: white;
  font-size: 14px;
  text-align: center;
  line-height: 24px;
  border-radius: 50%;
}


.marquee-container {
  width: 100%;
  height: 1055px;
  overflow: hidden;
  position: relative;
  border: none;
  background: none;
}

.marquee-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  animation: marquee 10s linear infinite;
}

.marquee-content img {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
  border-radius: 10px;
}

@keyframes marquee {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-50%);
  }
}

/* Duplicate images for smooth infinite effect */
.marquee-content {
  display: flex;
  flex-direction: column;
  animation: marquee 10s linear infinite;
}

.marquee-content img {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
  border-radius: 10px;
}

/* Clone the images to create an infinite loop */
.marquee-content::after {
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
}




/* Contact Section */
.contact-section {
  background: #fff;
  padding: 60px 0;
}

.contact-sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.contact-info {
  width: 45%;
}

.contact-info h2 {
  font-size: 32px;
  font-weight: bold;
  color: #222;
}

.contact-info h2 span {
  color: #111;
}

.contact-info p {
  font-size: 16px;
  margin: 15px 0;
}

.social-icons {
  margin-top: 20px;
}

.social-icons a {
  margin-right: 10px;
  font-size: 20px;
  color: #555;
  text-decoration: none;
}

.contact-form {
  width: 45%;
}

.form-group {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

input,
select,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

textarea {
  height: 100px;
}

button {
  display: inline-block;
  padding: 12px 20px;
  background: #fde8d8;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

button:hover {
  background: #ffbb99;
}

/* Footer */
footer {
  background: #f5f5f5;
  padding: 50px 10%;
  text-align: center;
}

.footer-content h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.footer-content p {
  font-size: 14px;
  margin-bottom: 15px;
}

.footer-content input {
  padding: 10px;
  width: 40%;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.footer-nav {
  margin-top: 30px;
}

.footer-nav a {
  margin: 0 10px;
  font-size: 14px;
  text-decoration: none;
  color: #333;
}

.footer-nav a:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media screen and (max-width: 1024px) {
  .container {
    flex-direction: column;
    align-items: center;
  }

  .contact-info,
  .contact-form {
    width: 100%;
    text-align: center;
  }

  .form-group {
    flex-direction: column;
  }

  input,
  select,
  textarea {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .footer-content input {
    width: 80%;
  }

  .footer-nav a {
    display: block;
    margin-bottom: 10px;
  }
}



.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: #000;
}

.header-main {
  background: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-bottom: 1px solid #eee;
}

.nav {
  display: flex;
  gap: 20px;
}

.nav a {
  text-decoration: none;
  color: black;
  font-size: 1rem;
}

.cta-buttons {
  display: flex;
  gap: 10px;
}

.btn {
  padding: 8px 15px;
  border: 2px solid black;
  background: black;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: 0.3s;
}

.btn:hover {
  background: white;
  color: black;
}

.mobile-menu {
  display: none;
  font-size: 1.5rem;
  cursor: pointer;
}

@media (max-width: 768px) {

  .nav,
  .cta-buttons {
    display: none;
  }

  .mobile-menu {
    display: block;
  }
}


.hero {
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(https://images.unsplash.com/photo-1617463874381-85b513b3e991?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D);
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
  height: auto;
  overflow: hidden;
  pointer-events: none;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  will-change: transform, opacity;
  border-radius: 20px;
}

.hero h1 {
  color: #fff;
  letter-spacing: 3px;
  font-weight: 900;
  font-size: 75px;
  padding: 85px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hero a {
  color: #fff !important;
  letter-spacing: 1px;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ff3131;
  width: 12%;
  margin: 0 auto;
  position: relative;
  margin-top: -50px;
  margin-bottom: 100px;
  padding: 10px;
  border-radius: 5px;
}


.gt3-page-title__content {
  width: 100%;
  margin-top: 60px;
}

.gt3-page-title__content .page_title,
.gt3-page-title__content .gt3_breadcrumb {
  display: table-row;
  vertical-align: middle;
}

.gt3_breadcrumb {
  font-size: .875em;
}

.gt3-page-title__content .breadcrumbs {
  white-space: nowrap;
}

.gt3-page-title__content .breadcrumbs:before {
  display: inline-block;
  width: 20px;
  height: 2px;
  margin: 0 10px 0 2px;
  content: '';
  vertical-align: middle;
  background-color: currentColor;
}

.gt3-page-title__content .gt3_breadcrumb a {
  color: #626262;
}

.gt3-page-title__content .gt3_breadcrumb a {
  margin: 0;
  padding: 0 1px;
}


.gt3_breadcrumb {
  font-size: .875em;
}

.gt3-page-title__content .gt3_breadcrumb .current {
  font-weight: 400;
  margin: 0;
  padding: 0 1px;
}



.gt3-page-title__content .page_title h1 {
  line-height: 1.58em;
  color: inherit;
}


.benifits {
  background-image: url(https://livewp.site/wp/md/moone/wp-content/uploads/sites/5/2018/03/bg_home15-1.jpg);
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
}

.benifits .part {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.benifits .part .part-1 {
  width: 50%;
}

.benifits .part .part-2 {
  margin-right: auto;
  max-width: 570px;
  padding: 65px;
}

.benifits .part .part-2 .content {
  align-content: flex-end;
  align-items: flex-end;
  padding: 0px 0px 0px 15px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}


.part-2 .tabs {
  display: flex;
}

.part-2 .tab {
  margin-right: 30px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 20px;
  color: black;
  transition: 0.3s;
  position: relative;
}

.part-2 .tab.active {
  font-weight: bold;
}

.part-2 .tab.active::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 3px;
  background-color: black;
}

.tab-content {
  display: none;

}

.tab-content.active {
  display: block;
}

.process-img {
  position: absolute;
  right: 0;
  top: 0px;
  width: 408px;
  opacity: 0.5;
  transform: scaleX(-1);
}


.widget-btn {
  text-align: right;
}

.widget-btn a {
  color: #CC929F;
  font-weight: 600;
  text-decoration: none;
}


.banner-promo {
  background: url(reassets/Images/slider_2-1.jpg);
  height: 640px;
  background-size: contain;
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
}

.banner-promo h2 {
  font-size: 50px;
  font-weight: 600;

}